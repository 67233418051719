<template>
<div>
<v-container fluid style="width:90%; margin-top:10px;">
    <v-container bg fill-height grid-list-md text-xs-center style="max-width: 800px">
        <v-row align="center">

<h2>CONTRATO</h2>   
<br/><br/>    
  <br/> 

        </v-row>
    </v-container>
</v-container>
<rodape/>

</div>

</template>

<script>
import Rodape from '../views/itensPage/RodapeInterno';
import { mapGetters, mapActions } from 'vuex'

export default {

 components: { Rodape },

 computed:{
    ...mapGetters([
            'contrato_selecionado' , 'plano_selecionado' , 'listadependentes'
        ]),
  },

  


}

</script>
