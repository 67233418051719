import { http } from './config'


class uploadFilesService {
  upload(id, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("foto", file);
    formData.append("pasta", id);
    const user = JSON.parse(sessionStorage.getItem('user'))

    const headers = {  
      "Content-Type": "multipart/form-data",
      'Authorization': 'Bearer ' + user.accessToken
      }

    //return http.post('contrato', contrato , { headers: authHeader()})


    return http.post("foto",  formData, {
      headers: headers,
      onUploadProgress
    });
  }

  getFiles() {
    const user = JSON.parse(sessionStorage.getItem('user'))

    const headers = {  
      "Content-Type": "multipart/form-data",
      'Authorization': 'Bearer ' + user.accessToken
      }
    return http.get("/files", {headers: headers});
  }
}

export default new uploadFilesService();
