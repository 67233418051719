import { http } from './configOpen'


export default{

    lista:() => {
        return http.get('produtos')
    },


    produto: (id) => {
        return http.get('produtos/'+id , { headers: authHeader() })
    },

    existe_token: (token) => {
        
        return http.get('existe_token/'+token)

    }
    
   
}