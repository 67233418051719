import axios from 'axios'
//const url = 'http://localhost:8091/api/'
const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/api/'

//const url = 'https://api.gpdf.com.br/apiprevidateste/api/'
//const url = process.env.VUE_APP_SERVER_URI

export const http = axios.create({
     baseURL: url, 
})

export default {
     url,
}