import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueMask from 'v-mask'
//import VueRouter from 'vue-router'
import store from './store'
import VueHtmlToPaper from 'vue-html-to-paper';
import VueFilterDateFormat from 'vue-filter-date-format';

Vue.use(VueFilterDateFormat);
Vue.use(VueMask);
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(VueHtmlToPaper);
//require('dotenv').config()
import dotenv from 'dotenv'
dotenv.config()


router.beforeEach((to, from, next) => {

  if(to.path != '/privacidade' && to.path != '/modelo'){

    if(to.path != '/'){
      if(store.state.plano_selecionado.id != '0'){
        
      }else{

        router.push('/');
      }
  }
}else{

  //router.push('/privacidade');

}

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
};

Vue.use(VueHtmlToPaper, options);


next()
}),



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

