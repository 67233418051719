import { http } from './config'
import authHeader from './auth-header';


export default{

   

    salvar:(contrato) => {
        return http.post('contrato', contrato , { headers: authHeader()})
    },

    valores:(id) => {
        return http.get('valores/'+ id , { headers: authHeader()})
    },

    
    
   
}