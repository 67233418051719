<template>
  <v-footer
    dark
    padless
  >

  


    <v-card style="width:100%"
      flat color="#0b4269"
      tile class="white--text text-center"

    >

    <v-container fluid>
  <v-layout justify-center align-center>
    <v-flex shrink>
    <v-row>
      <v-col>
          <v-img class="justify-center"  
            src="https://maestrix.grupoparque.com.br/LOGOSVG7.svg"
            max-height="100"
            max-width="100">
          </v-img>
      </v-col>
      <v-col>
           <v-img class="justify-center"  
            src="https://maestrix.grupoparque.com.br/LOGOSVG4.svg"
            max-height="100"
            max-width="100">
          </v-img>
      </v-col>
    </v-row>

      </v-flex>
  </v-layout>
</v-container>
  

      <v-card-text>
      
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>


      <v-divider></v-divider>

      <v-card-text class="white--text pt-0">
        {{ new Date().getFullYear() }} — Previparq. Todos os direitos reservados. <br/>

        Rua José de Alendar, 121 - 0800 009 1700
      </v-card-text>
     

    </v-card>

    


  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-instagram',
        'mdi-whatsapp'
      ],
    }),
  }
</script>