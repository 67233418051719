<template>

  <v-app-bar app style="height:80px; background-color: #0b4269">

    <v-container style="width:50%; min-width: 280px;">
          <v-stepper  class="elevation-0" v-model="this.posEtapa" style="margin-top:20px; background-color:transparent">
            <v-stepper-header style="">
              <v-stepper-step step="1" color="blue">
                <span class="white--text">Dados do Titular</span> 
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" color="red">
                  <span class="white--text">Endereço</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" color="orange">
                  <span class="white--text">Dependentes</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4" color="blue">
                <span class="white--text">Verificação</span>
              </v-stepper-step>

              <v-stepper-step step="5" color="green">
                <span class="white--text">Conclusão</span>
              </v-stepper-step>


            </v-stepper-header>
          </v-stepper>
    </v-container>
  </v-app-bar>
</template>

 
<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
  computed:{
    ...mapGetters([
            'posEtapa'
        ]),
  },
  }
 </script>
    