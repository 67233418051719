import axios from 'axios'
//const url = 'http://localhost:8091/apiopen/'
const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/apiopen/'

//const url = 'https://api.gpdf.com.br/apiprevidateste/apiopen/'


export const http = axios.create({
     baseURL: url, 
})

export default {
     url,
}

