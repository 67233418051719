<template>
<div>
  <cabecalho/>
  <v-form ref="form" style="margin-top:20px">
    <v-container fluid style="width:90%; margin-top:10px;">
        
        <v-banner style="margin-top:20px;" single-line>
          <template v-slot:actions>
            <v-btn color="green darken-1 white--text" class="mr-4" @click="prosseguir"> Prosseguir 
                <v-icon right dark>mdi-arrow-right</v-icon>
            </v-btn>
          </template>
        </v-banner>
<br/>


      <form @submit.prevent="salvar">
        <v-row align="center">
          
          <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-text-field  label="Nome Completo" :rules="[v => !!v || 'Campo Obrigatório']"  v-model="dependente.nome"  outlined></v-text-field>    
          </v-col>
          <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-text-field  label="CPF"  type="text" v-model="dependente.cpf"   v-mask="'###.###.###-##'"  outlined></v-text-field>    
          </v-col>
          <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-text-field  label="Nascimento" :rules="[v => !!v || 'Campo Obrigatório']"  v-mask="'##/##/####'" v-model="dependente.nascimento" outlined></v-text-field>    
          </v-col>

          <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-select v-model="dependente.sexo"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="sexos"  label="Sexo" outlined></v-select>
          </v-col> 

          <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-select v-model="dependente.tipo"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="tipo"  label="Tipo" outlined></v-select>
          </v-col> 

          <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-select v-model="dependente.parentesco"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="parentescos" s label="Parentesco" outlined></v-select>
          </v-col> 

          <v-col class="d-flex" style="height:80px;" cols="12" sm="6">
            <v-checkbox v-model="dependente.tiket" >
                <template v-slot:label>
                  <div>
                    Ticket Alimentação
                   </div>
                </template>
            </v-checkbox>
          </v-col> 

           <v-col class="d-flex" style="height:80px" cols="12" sm="6">
            <v-checkbox v-model="dependente.beneficio" >
                <template v-slot:label>
                  <div>
                    Beneficiário
                   </div>
                </template>
            </v-checkbox>
          </v-col> 


          <br/>
        </v-row>
        <br/>
        <h5>
        * Limite de {{plano_selecionado.qtd_dep}} Dependentes <br/>
        * Limite de {{plano_selecionado.qtd_agr}} Agregados <br/>
        * Valor para Agregado até 65 anos R$ {{plano_selecionado.custo_ate_65_anos}}  <br/>
        * Valor para Agregado maior 65 anos R$  {{plano_selecionado.custo_maior_65_anos}} <br/>
        * Valor para Agregado maior 80 anos R$  {{plano_selecionado.custo_maior_80_anos}} 
        </h5>
        <v-banner style="margin-top:12px"
            single-line>
            <template v-slot:actions>

              <v-btn 
              color="success"
              class="mr-1"
              @click="adicionar">
              Adicionar 
                    <v-icon right dark>mdi-account-plus</v-icon>
            </v-btn>
            </template>
        </v-banner>
        <br/>
        
        <v-snackbar v-model="snackbar">
              {{ text_snack }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  FECHAR
                </v-btn>
              </template>
        </v-snackbar>

        <v-row>
        <v-simple-table  fixed-header style="width:100%"
          height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nome</th>
                <th class="text-left">Nascimento</th>
                <th class="text-left">Tipo</th>
                <th class="text-left">Ticket</th>
                <th class="text-left">Beneficiário</th>
                <th class="text-left">Custo</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listadependentes"
                :key="item.nome"
              >
                <td>{{ item.nome }}</td>
                <td>{{ item.nascimento }}</td>
                <td>{{ item.tipo }}</td>
                <td>
                      <v-chip
                          class="ma-2"
                          :color="getBadge(item.tiket)"
                          text-color="white">
                              {{getStatus(item.tiket)}} 
                      </v-chip>
                
                </td>
                <td>
                      <v-chip
                          class="ma-2"
                          :color="getBadge(item.beneficio)"
                          text-color="white">
                              {{getStatus(item.beneficio)}} 
                      </v-chip>
                
                </td>
                <td>R$ {{ item.custo }}</td>

                <td> 
                  
                  <v-btn
                    class="ma-2"
                    outlined @click="remover(item)"
                    color="red"
                  >
                    Excluir
                  </v-btn>
                  </td>
              </tr>
            </tbody>
            <br/>
            <br/>
          </template>
        </v-simple-table>
        </v-row>

        <br/>
<v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="clear" color="green darken-1" text> Limpar</v-btn>
          <v-btn color="green darken-1 white--text" class="mr-1" @click="prosseguir"> Prosseguir 
              <v-icon right dark>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>


       
      </form>
    </v-container>  
  </v-form>

  <v-dialog
      v-model="dialog_pular"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Informação
        </v-card-title>
        <v-card-text><b>Cadastro dos Dependentes</b> <br/><br/>Você pode continuar o preenchimento do formulário de cadastro, 
        ou pular essa etapa, caso não tenha em mãos todos os documentos necessários. Fique tranquilo(a) que entraremos em contato para 
        finalizar o cadastro junto com você. Ah, e fique atento(a) ao seu telefone que entraremos em contato em breve!
        <br/>Aviso: Caso exista Agregado o valor será incluído na adesão do plano.
        </v-card-text>
       
        <v-card-actions>    
          <v-btn block
            color="orange"     
            @click="dialog_pular = false"
          >
            Preencher agora
          </v-btn>

         </v-card-actions>

         <v-card-actions>    
          <v-btn block
            color="orange"     
            @click="prosseguir"
          >
            Pular etapa
          </v-btn>

         </v-card-actions>

              <br/>

      </v-card>



    </v-dialog>


<rodape/>

</div> 
</template>

<script>

import Contrato from '../services/contrato'
import Dependente from '../services/dependentes'
import Rodape from '../views/itensPage/RodapeInterno';
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import ValidarCPF from '../services/cpf';
import Auxiliar from '../services/auxiliar';
import Cabecalho from '../views/itensPage/Cabecalho';



  export default {
  components: { Cabecalho, Rodape },
  
 


  created() {

    this.$store.commit('setValorPosEtapa', 3)

    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }

    if(this.etapa2 == false){
      this.$router.push('/endereco')
    }



    if(this.listadependentes.length>0){
     for(let i = 0; i < this.listadependentes.length; i = i + 1 ) {

            if(this.listadependentes[i].tipo == 'Agregado'){
                  this.qtd_agre_aux = this.qtd_agre_aux + 1
            }else{
                  this.qtd_dep_aux = this.qtd_dep_aux + 1
            }


        }
    }

 },

  computed:{
    ...mapGetters([
            'contrato_selecionado' , 'plano_selecionado', 'valor_total_agregado' , 'listadependentes', 'etapa2'
        ]),
      
      /*
    save_contrato:{
          set(v){
              this.$store.commit('setIdContrato', v)
          },
          get(){
             return this.$store.state.contrato;

          }
      },
*/

      isAuthenticated() {
        return this.$store.getters.isAuthenticated
      },

  },


    data: () => ({ 
        dialog_pular: true,
        showload: false,
        snackbar: false,
        text_snack: '',
        qtd_dep_aux: 0,
        qtd_agre_aux: 0,
        total_dep_45_aux:0,
        total_dep_65_aux:0,
        total_dep_70_aux:0,

        disabled: 1,
        tiket: false,
        beneficio: false,
        dependente: {
          nome: '',
          nascimento: '',
          cpf: '',
          sexo: '',
          tipo: '',
          custo: '',
          parentesco: '',
          id_contrato: '',
          tiket: false,
          beneficio: false,
          percentual: 0,
        },
        //dependentes:[],
        enderecocep: {},
        contrato:{
        nome: '',
        cpf: '',
        sexo: '',
        nacionalidade: '',
        estadocivil: '',
        dataCarencia: '',
        vencimento: '',
        dataCarencia: '',
        condicao: '',
        qtdParcelas: '',
        id_plano: '',
        descricao_plano: '',
        nome_plano: '',
        valorContrato: '',
        parcela: '',
        tipo: '',
        nascimento: '',
        rg: '',
        uf_expedidor: '',
        expedidor: '',
        mae: '',
        religiao: '',
        profissao: '',
        pai: '',
        cep: '',
        logradouro: '',
        complemento: '',
        endereco: '',
        n_res: '',
        bairro: '',
        cidade: '',
        uf_end: '',
        pais: '',
        cep_entrega: '',
        repetir_endereco: true,
        complemento_entrega: '',
        endereco_entrega: '',
        n_res_entrega: '',
        bairro_entrega: '',
        cidade_entrega: '',
        uf_end_entrega: '',
        pais_entrega: '',
        telefone_fixo: '',
        celular: '',
        email: '',
        celular2: '',
        id: 0,
      },

 rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
  
    select: null,

    estadocivil: [
        'Casado',
        'Soleiro',
        'Divorciado',
        'Viúvo(a)',
      ], 

       parentescos: [
        'CONJUGE OU COMPANHEIRO(A)',
        'PAI',
        'MÃE',
        'SOGRO(A)',
        'FILHO(A)',
        'NETO(A)',
        'IRMÃO',
        'AVÓ(Ô)',
        'PRIMO(A)',
        'TIO(A)',
        'SOBRINHO(A)',
        'NORA',
        'GENRO',
        'CUNHADO(A)',
        'OUTROS',
        'NENHUM',


      ], 


      sexos: [
        'Masculino',
        'Feminino',
      ], 
       tipo: [
        'Dependente',
        'Agregado',
      ], 
    


    }),

  

  methods:{
      voltar(){
           this.$router.push('endereco')
      },
      async prosseguir(){
        this.showload = true;
        this.contrato_selecionado.nome_plano = this.contrato_selecionado.descricao_plano;
        Contrato.salvar(this.contrato_selecionado).then(response => {
        this.$store.commit('setIdContrato', response.data.id)
          //alert(response+'salvo com sucesso!' + response.data.id)

          if(this.listadependentes.length>0){
              Dependente.salvar(this.listadependentes, this.contrato_selecionado.id).then(response => {
                this.$store.commit('setListaDependente', response.data)
              }).catch(e =>{

              }).finally(() => {
                this.showload = false;
                this.$store.commit('setValorEtapa3', true)

                if(this.contrato_selecionado.id != 0){
                this.$router.push('verificacao')
                }
            });

            }else{
              if(this.contrato_selecionado.id != 0){
                 this.$store.commit('setValorEtapa3', true)

                this.$router.push('verificacao')
              }
            }

          //this.lista()
        }).catch(e =>{
           this.showload = false;
        }).finally(() => {
          // this.showload = false;
          //this.$router.push('contratual')
      });


      
        
       //this.salvar();
       // this.$router.push('contratual')
       
      },
  

  
      liberarDA(qtd, tipo){

        if(tipo == 'Agregado'){
              if(qtd >= this.plano_selecionado.qtd_agr){
                return false
              }else return true

        }else {
              if(qtd >= this.plano_selecionado.qtd_dep){
               return false
              }else return true

        }
      },
       remover(item){
            this.$store.commit('removerDependente', item.nome)

            if(item.tipo == 'Agregado'){
              this.qtd_agre_aux = this.qtd_agre_aux - 1
            }else{
              this.qtd_dep_aux = this.qtd_dep_aux - 1
            }
            if(this.qtd_dep_aux<0){
                qtd_dep_aux = 0
            }
            if(this.qtd_agre_aux<0){
              qtd_agre_aux = 0
            }

            var total = this.valor_total_agregado - item.custo;
            this.$store.commit('setValorTotalDependente', total)
            

            if(item.tiket == true){
                this.tiket = false;
            }



            this.text_snack = "Removido com sucesso!";   
            this.snackbar = true;

       },

     getStatus(status) {
      switch (status) {
        case true: return 'Sim'
        case false: return 'Não'
        default: ''
      }
     },

      getBadge (status) {
      switch (status) {
         case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'success'
      }
      },

      adicionar(){
         var idade = Auxiliar(this.dependente.nascimento);
        this.text_snack = '';

          if(this.$refs.form.validate()){
            var liberado = false;

            if(this.dependente.tipo == 'Agregado'){
                  if(this.liberarDA(this.qtd_agre_aux, 'Agregado' )){
                      liberado = true; 
                  } else{    
                    this.text_snack = "Limite de Agregado é " + this.plano_selecionado.qtd_agr;   
                    }  
            }else

            if(this.dependente.tipo == 'Dependente'){
                  if(this.liberarDA(this.qtd_dep_aux, 'Dependente' )){
                      liberado = true; 
                  }else{
                      this.text_snack = "Limite de Dependente é " + this.plano_selecionado.qtd_dep;   
                  }   
            }
        
       // var teste = ValidarCPF(this.dependente.cpf);
        if(this.dependente.cpf != null){
          if(this.dependente.cpf != ""){
            if(!ValidarCPF(this.dependente.cpf)){
                this.text_snack = "CPF não é valido";   
                liberado = false;
            }
          }
        }

        if(this.tiket == true && this.dependente.tiket == true){
                this.text_snack = "Você já escolheu o Dependente que vai receber o Ticket";   
                liberado = false;

        }

        if(liberado){            
             if(this.dependente.tipo == 'Dependente'){
              
              
              if(idade>65){
                
                if(this.total_dep_70_aux >= this.plano_selecionado.total_dep_70){
                    liberado = false
                    this.text_snack = "Só é permitido "+this.plano_selecionado.total_dep_70+" dependentes acima de 65 anos"; 
                }

                if(idade>70){
                    liberado = false
                    this.text_snack = "Acima de 70 anos o cliente só pode entrar como Agregado."; 
                }

              }else if(idade>45){

                if((this.total_dep_70_aux + this.total_dep_65_aux)  >= (this.plano_selecionado.total_dep_70+ this.plano_selecionado.total_dep_65)){
                     liberado = false
                     this.text_snack = "Só é permitido "+ (this.plano_selecionado.total_dep_70+ this.plano_selecionado.total_dep_65)+" dependentes acima de 45 anos";   
                }



              }

          }
        }

        if(liberado){
            var idade = Auxiliar(this.dependente.nascimento);
            
            var custo_localizado = 0;
            
            if(this.dependente.tipo == 'Agregado'){
                 if(idade>65){
                    custo_localizado = this.plano_selecionado.custo_maior_65_anos;
                }if(idade>80){
                    custo_localizado = this.plano_selecionado.custo_maior_80_anos;
                }else{
                    custo_localizado = this.plano_selecionado.custo_ate_65_anos;

                }
            }
           
            var total = this.valor_total_agregado;
            this.dependente.custo = custo_localizado;

            this.listadependentes.push(this.dependente);
            this.$store.commit('setValorTotalDependente', total+ custo_localizado)
            this.$store.commit('setDependente', this.dependente)

             if(this.dependente.tipo == 'Agregado'){
                  this.qtd_agre_aux = this.qtd_agre_aux + 1
            }else{
                  this.qtd_dep_aux = this.qtd_dep_aux + 1
            }

            if(this.dependente.tipo == 'Dependente'){
                var idade = Auxiliar(this.dependente.nascimento);
                if(idade<=45){
                  this.total_dep_45_aux = this.total_dep_45_aux + 1;
                }else if(idade<=65) {
                   this.total_dep_65_aux = this.total_dep_65_aux + 1;
                }else if(idade<=70) {
                   this.total_dep_70_aux = this.total_dep_70_aux + 1;
                }

            } 
            if(this.dependente.tiket == true){
              this.tiket = true;
            }




                this.clear();
                this.dependente.tiket = false;
                this.dependente.beneficio = false;
          }else{
            this.snackbar = true;
          
          }

        }
      } , 
      
      clear(){
        this.dependente = {};
        this.$refs.form.reset()
     },

          

    

          
  }
  }

</script>
