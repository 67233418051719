<template>
<div>
  <cabecalho/>
  
  
   <v-container fluid style="width:90%; margin-top:10px;">
      </br>
      </br>
      <v-container bg fill-height grid-list-md text-xs-center style="max-width: 500px">
        
        Estamos quase lá! Para prosseguir com a contratação do seu plano Previda, com CPF do titular de final *****{{this.contrato_selecionado.cpf.substring(this.contrato_selecionado.cpf.length, this.contrato_selecionado.cpf.length - 8)}}, enviamos um código de validação para o telefone {{this.contrato_selecionado.celular.substring(this.contrato_selecionado.celular.length - 15, this.contrato_selecionado.celular.length - 11)}} ***** {{this.contrato_selecionado.celular.substring(this.contrato_selecionado.celular.length - 0, this.contrato_selecionado.celular.length - 4)}}
      
      </br>
      </br>
      </br>
      <v-row align="center">

        <v-col cols="12" sm="12">
          
          <div v-if="this.time == 0">
              <v-btn :loading="showload" block color="blue lighten-2" 
                      class="mr-4" @click="reenviarcodigo"> 
                      REENVIAR CÓDIGO
              </v-btn>
          </div>
          
          <div v-else>
              <v-btn :loading="showload" block color="blue lighten-2"  disabled=true
                      class="mr-4"> Tempo restante  {{timeLeft}}
              </v-btn>
          </div>      

        </v-col>
       
       </v-row>
     

      <v-form ref="form">
      <v-row align="center" >

        <v-col class="d-flex"  cols="12" sm="12" align="center">
            <v-col cols="12" sm="12">
              <v-text-field   maxlength = "12" style="font-size:40px;text-align: center;" outlined 
                 v-model="sms1"  data-index="0" ref="input-0">
              </v-text-field>
            </v-col>
        </v-col>    
      </v-row>      

      </v-col>
      </v-form>
 
       <v-col cols="12" sm="12">
                    <v-btn :loading="showload"  color="orange" outlined
                    class="mr-4" @click="limparCodigo">Limpar 
                    </v-btn>
                </v-col>
    </v-row>

  </v-container>
  


<br/>

      <v-card-actions >
          <v-container bg fill-height grid-list-md text-xs-center style="max-width: 500px">
            
               

                <v-col cols="12" sm="12">
                    <v-btn :loading="showload" x-large  block color="success" 
                    class="mr-4" @click="validarCodigo">CONFIRMAR 
                          <v-icon right dark>mdi-check</v-icon>
                    </v-btn>
                </v-col>



                
              <v-col cols="12" sm="12">
                   <v-btn :loading="showload" x-large block color="error"
                    class="mr-4"
                    @click="sair">SAIR
                          <v-icon right dark>mdi-close</v-icon>
                  </v-btn>
              </v-col>
            </v-row>
          </v-container>
      </v-card-actions>
         <v-snackbar v-model="snackbar" absolute
      centered
      color="red accent-2">
              {{ text_snack }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  FECHAR
                </v-btn>
              </template>
        </v-snackbar>
    </v-container>

<rodape/>

    
  </div> 

  

</template>

<script>

import Contrato from '../services/contrato'
import Verificar from '../services/verificacao'

import Pagamento from '../services/pagamento'
import Rodape from '../views/itensPage/RodapeInterno';

import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Cabecalho from '../views/itensPage/Cabecalho';
//import FormDataPost from '..services/upload';

//...

  export default {

components: {Cabecalho, Rodape },

     

  created() {

    this.timer = setInterval(this.decrementOrAlert, 1000)

    this.$store.commit('setValorPosEtapa', 4)
    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }


    if(this.etapa3 == false){
      this.$router.push('/dependente')
    }
    
 },

  beforeMount(){

    this.analise_request();

     
  },

  computed:{
     

    ...mapGetters([
            'contrato_selecionado' ,  'etapa3', 'etapa4' 
        ]),
        	timeLeft () {
          //if(this.sms_ini == false){
            return `${this.minutes} : ${this.seconds} `
          //}
        },
        minutes () {
          return String(Math.floor(this.time / 60)).padStart(2, '0')
        },
        seconds () {
          return String(this.time % 60).padStart(2, '0')
        },
       
       
  },

  
 data: () => ({ 

        msg_up: '',
        teste: 1,
        fileInfos: [],
        dialog: false,
        sms_ini:false,
        time: 300, //in seconds
        timer: null,
        showload: false,
        snackbar: false,
        text_snack: '',
        sms1:'',
    }),


  methods:{
      analise_request(){
        

        if(this.etapa4 == false){
            this.criarcodigo();
          }else{
            this.$router.push('/contratual')
          }
      },


      decrementOrAlert () {
        if (this.time > 0) {
          this.time--
          return
        }
        
        clearInterval(this.timer)
      },

      
      criarcodigo(){
       if(this.sms_ini == false){
          Verificar.criarcodigo(this.contrato_selecionado.id, 'SMS').then(response => {
            this.sms_ini = true
              }).catch(e =>{
              }).finally(() => {
                this.showload = false;
          });
        }
      },
      
      limparCodigo(){
        this.$refs.form.reset();
          this.val1 = false,
          this.sms1 = "";
      },
      reenviarcodigo(){
        Verificar.reenviarcodigo(this.contrato_selecionado.id, 'SMS').then(response => {
              this.sms_ini = false
              this.time = 300;
              //this.timeLeftl
              this.timer = setInterval(this.decrementOrAlert, 1000)

            }).catch(e =>{
              alert('Problemas com o servidor, por favor tente novamente mas tarde')
            }).finally(() => {
              this.showload = false;
        });
      },

      sair(){
           this.$router.push('')
      },

      validarCodigo(){
        
        const valor = this.sms1;
        Verificar.verificarcodigo(valor).then(response => {
            //this.$store.commit('setContrato', this.contrato_selecionado)
            this.$store.commit('setValorEtapa4', true)

            if(response.data == 'Sucesso'){
                this.$router.push('contratual')
            }else{
                  this.text_snack = response.data;   
                  this.snackbar = true;
            }

            }).catch(e =>{
              alert('Problemas com o servidor, por favor tente novamente mas tarde')
            }).finally(() => {
        });
      },

      play() {
                this.timerEnabled = true;
            },

            pause() {
                this.timerEnabled = false;
            },

    onChange(v) {
      console.log("onChange ", v);
    },
    onComplete(v) {
      console.log("onComplete ", v);
    },
    aceite_termo_two(){
          this.$store.commit('setAceite2', true)
    },

      
      setDisabled: function(){
            if(this.aceito1 == false || this.aceito2 == false){
              return true
            }else { return false}
      },


      concluir(){
            this.$store.commit('setIdPlano', '')
            this.$router.push('/')
      },
    }
  }

</script>

