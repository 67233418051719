import { http } from './config'
import authHeader from './auth-header';


export default{

   

  
    salvar:(dependentes, id_contrato) => {

        
        var list;

        for(let i = 0; i < dependentes.length; i = i + 1 ) {
                dependentes[i].contrato = id_contrato;

        }

        return http.post('dependente', dependentes , { headers: authHeader()})
    },

   
}