import { http } from './config'
import authHeader from './auth-header';


export default{
   
    salvar:(pagamento) => {
        return http.post('pagamento', pagamento , { headers: authHeader()})
    },

   
    ler_bandeira:(pagamento) => {
        return http.post('bandeira', pagamento , { headers: authHeader()})
    },


}