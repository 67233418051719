import { http } from './config'
import authHeader from './auth-header';

export default {

    criarcodigo: (contrato, tipo) => {
        return http.get('criarcodigo/'+contrato+'/'+tipo , { headers: authHeader() })
    },

    verificarcodigo: (codigo) => {
        return http.get('verificarcodigo/'+codigo , { headers: authHeader() })
    },

    reenviarcodigo: (contrato, tipo) => {
        return http.get('reenviarcodigo/'+contrato+'/'+tipo , { headers: authHeader() })
    },
}
