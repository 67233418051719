<template>
<div >  
  <div class="conf_mobile">
    	<div class="elementor-widget-container e-grid-align-mobile-center" style="text-align:center;">
																<a href="#">
							<img width="150" height="120" src="../assets/img_log_gra_branca.png"  alt="" loading="lazy">								</a>
															</div>
  </div>
  <div class="conf_desktop">
   <div data-elementor-type="header" data-elementor-id="20" class="elementor elementor-20 elementor-location-header" data-elementor-settings="[]">
		<div class="elementor-section-wrap">
					<section class="elementor-section elementor-top-section elementor-element elementor-element-73c52d2d elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInDown" data-id="73c52d2d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInDown&quot;}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-61d4d85b" data-id="61d4d85b" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-1a761671 elementor-icon-list--layout-inline elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="1a761671" data-element_type="widget" data-widget_type="icon-list.default">
				<div class="elementor-widget-container">
					<ul class="elementor-icon-list-items elementor-inline-items">
							<li class="elementor-icon-list-item elementor-inline-item">
											<a href="https://www.google.com/maps/search/previda/@-9.6480593,-35.7355608,20z" target="_blank">

												<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-map-marker-alt"></i>						</span>
										<span class="elementor-icon-list-text">Rua José de Alencar, nº 121</span>
											</a>
									</li>
						</ul>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5df847af" data-id="5df847af" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-36b54cd e-grid-align-right e-grid-align-mobile-center elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="36b54cd" data-element_type="widget" data-widget_type="social-icons.default">
				<div class="elementor-widget-container">
					<div class="elementor-social-icons-wrapper elementor-grid">
							<span class="elementor-grid-item">
					<a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-08d4749" href="https://www.instagram.com/previda_/" target="_blank">
						<span class="elementor-screen-only">Instagram</span>
						<i class="fab fa-instagram"></i>					</a>
				</span>
							<span class="elementor-grid-item">
					<a class="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-repeater-item-e7605bf" href="https://www.facebook.com/PrevidaAlagoas" target="_blank">
						<span class="elementor-screen-only">Facebook-f</span>
						<i class="fab fa-facebook-f"></i>					
            
            </a>
				</span>
							<span class="elementor-grid-item">
					<a class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-repeater-item-cbbac78" href="https://www.youtube.com/user/tvprevida" target="_blank">
						<span class="elementor-screen-only">Youtube</span>
						<i class="fab fa-youtube"></i>					</a>
				</span>
							<span class="elementor-grid-item">
					<a class="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-repeater-item-10bcba3" href="https://api.whatsapp.com/send?phone=55828000091700&amp;text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Previda" target="_blank">
						<span class="elementor-screen-only">Whatsapp</span>
						<i class="fab fa-whatsapp"></i>					</a>
				</span>
					</div>
				</div>
				</div>
					</div>
		</div>
							</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-21effc56 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="21effc56" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-580c5ceb" data-id="580c5ceb" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-b64e4c2 elementor-widget elementor-widget-image" data-id="b64e4c2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
																<a href="#">
							<img width="304" height="99" src="https://previda.net/wp-content/uploads/2021/06/logo-previda.png" class="attachment-large size-large" alt="" loading="lazy" srcset="https://previda.net/wp-content/uploads/2021/06/logo-previda.png 304w, https://previda.net/wp-content/uploads/2021/06/logo-previda-300x98.png 300w" sizes="(max-width: 304px) 100vw, 304px">								</a>
															</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-31236756 elementor-hidden-phone" data-id="31236756" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<section class="elementor-section elementor-inner-section elementor-element elementor-element-33667dc9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="33667dc9" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6b2c8b8a" data-id="6b2c8b8a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-69ee8cbd elementor-position-left elementor-vertical-align-middle elementor-view-default elementor-widget elementor-widget-icon-box" data-id="69ee8cbd" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<a class="elementor-icon elementor-animation-" href="tel:+5508000091700" target="_blank">
				<i aria-hidden="true" class="fas fa-phone-alt"></i>				</a>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<a href="tel:+5508000091700" target="_blank">
						Atendimento 24h					</a>
				</h3>
									<p class="elementor-icon-box-description">
						<a href="tel:+5508000091700">
<span style="font-family: Poppins, sans-serif; font-style: italic;color:#315886; font-weight: normal">0800 009 1700</span>
</a>

					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-71b24f01" data-id="71b24f01" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-1dd644e7 elementor-position-left elementor-vertical-align-middle elementor-view-default elementor-widget elementor-widget-icon-box" data-id="1dd644e7" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<a class="elementor-icon elementor-animation-" style="color:#F07D00" href="https://api.whatsapp.com/send?phone=55828000091700&amp;text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Previda">
				<i aria-hidden="true" class="fab fa-whatsapp"></i>				</a>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<a href="https://api.whatsapp.com/send?phone=55828000091700&amp;text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Previda">
						<span style="font-weight: 500;"><i>Central de Vendas</i></span>					</a>
				</h3>
									<p class="elementor-icon-box-description">
						<a href="https://api.whatsapp.com/send?phone=55828000091700&amp;text=Acesso%20via%20site%3A%20Ol%C3%A1%2C%20quero%20entrar%20em%20contato%20com%20o%20Previda">
<span style="font-family: Poppins, sans-serif; font-style: italic;color:#315886;">0800 009 1700</span>
</a>					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1d215730" data-id="1d215730" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-6e18468f elementor-position-left elementor-vertical-align-middle elementor-view-default elementor-widget elementor-widget-icon-box" data-id="6e18468f" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<a class="elementor-icon elementor-animation-" style="color:#F07D00" href="mailto:contato@previda.net">
				<i aria-hidden="true"  class="far fa-envelope-open"></i>				</a>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<a href="mailto:contato@previda.net">
						<span style="font-weight: 500;color:#F07D00"><i><i>Atendimento Online</i></i></span><i>					</i></a><i>
				</i></h3><i>
									<p class="elementor-icon-box-description">
						<a href="mailto:contato@previda.net">
<span style="color: rgb(49, 88, 134); font-family: Poppins, sans-serif; font-style: italic;">contato@previda.com.br</span>
</a>
					</p>
							</i></div><i>
		</i></div><i>
				</i></div><i>
				</i></div><i>
					</i></div><i>
		</i></div><i>
							</i></div><i>
		</i></section><i>
					</i></div><i>
		</i></div><i>
							</i></div><i>
		</i></section><i>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-19a667b3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-sticky animated slideInUp" data-id="19a667b3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;sticky&quot;:&quot;top&quot;,&quot;sticky_on&quot;:[&quot;desktop&quot;],&quot;animation&quot;:&quot;slideInUp&quot;,&quot;sticky_offset&quot;:0,&quot;sticky_effects_offset&quot;:0}" style="">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-486ed6e5" data-id="486ed6e5" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-62f121e1 elementor-nav-menu__align-left elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu" data-id="62f121e1" data-element_type="widget" data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;<i class=\&quot;fas fa-caret-down\&quot;><\/i>&quot;,&quot;library&quot;:&quot;fa-solid&quot;},&quot;toggle&quot;:&quot;burger&quot;}" data-widget_type="nav-menu.default">
				<div class="elementor-widget-container">
						<nav migration_allowed="1" migrated="0" role="navigation" class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-slide"><ul id="menu-1-62f121e1" class="elementor-nav-menu" data-smartmenus-id="16306186396560938"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-97 current_page_item menu-item-394"><a href="https://previda.net/" aria-current="page" class="elementor-item elementor-item-active">Home</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-393"><a href="https://previda.net/quem-somos/" class="elementor-item">Quem Somos</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-504"><a href="https://previda.net/previdamais/" class="elementor-item">Previdamais</a></li>
<li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-396"><a href="https://meuprevida.gpdf.com.br/#/" class="elementor-item elementor-item-anchor">Planos</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-669"><a href="https://previda.net/lojas-previda/" class="elementor-item">Lojas</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-686"><a href="https://previda.net/blog/" class="elementor-item">Dicas e Notícias</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-798"><a href="https://previda.net/sorteios/" class="elementor-item">Sorteios</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-693"><a href="https://previda.net/contato/" class="elementor-item">Contato</a></li>
</ul></nav>
					<div class="elementor-menu-toggle" role="button" tabindex="0" aria-label="Menu Toggle" aria-expanded="false" style="">
			<i class="eicon-menu-bar" aria-hidden="true" role="presentation"></i>
			<span class="elementor-screen-only">Menu</span>
		</div>
			<nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" role="navigation" aria-hidden="true" style="width: 1435px; left: 0px; top: 44px;"><ul id="menu-2-62f121e1" class="elementor-nav-menu" data-smartmenus-id="16306186396596152"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-97 current_page_item menu-item-394"><a href="https://previda.net/" aria-current="page" class="elementor-item elementor-item-active" tabindex="-1">Home</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-393"><a href="https://previda.net/quem-somos/" class="elementor-item" tabindex="-1">Quem Somos</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-504"><a href="https://previda.net/previdamais/" class="elementor-item" tabindex="-1">Previdamais</a></li>
<li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-396"><a href="https://meuprevida.gpdf.com.br/#/" class="elementor-item elementor-item-anchor" tabindex="-1">Planos</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-669"><a href="https://previda.net/lojas-previda/" class="elementor-item" tabindex="-1">Lojas</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-686"><a href="https://previda.net/blog/" class="elementor-item" tabindex="-1">Dicas e Notícias</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-798"><a href="https://previda.net/sorteios/" class="elementor-item" tabindex="-1">Sorteios</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-693"><a href="https://previda.net/contato/" class="elementor-item" tabindex="-1">Contato</a></li>
</ul></nav>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-29a5b67" data-id="29a5b67" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-2433f512 elementor-align-justify elementor-widget elementor-widget-button" data-id="2433f512" data-element_type="widget" data-widget_type="button.default">
				<div class="elementor-widget-container">
					<div class="elementor-button-wrapper">
			<a href="https://pagamento.previda.net" class="elementor-button-link elementor-button elementor-size-sm" role="button">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Já sou cliente</span>
		</span>
					</a>
		</div>
				</div>
				</div>
					</div>
		</div>
							</div>
		</section>
				</i></div><i>
		</i></div>
    <br/>
 
</div>

<div >
  <v-row justify="center"  >

       

      <v-col cols="12" >
        <v-sheet class="mx-auto" style="background-color:white;"> 
          <v-slide-group style="justify-content:center;margin-left:4%;align-items: center;" v-model="model" 
             active-class="success"> 
            <v-slide-item v-for="n in model":key="n" v-slot="{ active, toggle }">
                 <div style="background-color:white; border-style: solid; border-color:#005587  ;margin-left:20px;width:300px;white-space:normal;" elevation="9"  class="rounded-xl">
                  <div style="width: 94%;margin-left: 3%;margin-top:10px">
                    <div class="text-center" style="font-family: unset;color: #005587;
                      font-size: unset;padding-top:20spx" :style="corBaseText">

                     <img src="../assets/img_plano.png" width="260" height="42" style="margin-top:12px">
                     <span style="font-size: 26px;font-weight: bold;font-family: 'Poppins';"> {{n.descricao.toUpperCase()}}</span>
                    </div>

                    <div class="text-center" style="font-size: 14px;font-weight: bold;font-family: unset;color: #005587;font-family: 'Poppins';">
                      {{n.total_dep}} dependentes | {{n.total_agr}} agregados <br/>
                      {{n.carencia_dependente}} dias de carência até 65 anos <br/>
                      {{n.carencia_dependente_maior}} dias de carência para maiores de 65 anos
                    </div>
              
                    <v-expansion-panels rounded class="mb-1" style="margin-top:4px">
                      <v-expansion-panel>
                        <v-expansion-panel-header rounded expand-icon="mdi-menu-down" style="background-color: #085587;color: white">
                          <h3>SERVIÇOS INCLUSOS</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content style="background-color: #085587;color: white">{{n.servicos}}
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> 

                    <div style="font-family: unset;color:#005587;font-size: 14px;font-weight: bold;margin-top:4px;font-family: 'Poppins';">
                    <ul>
                                            <li>{{n.message}}</li>
                        <li>{{n.message1}}</li>
                        <li>{{n.message2}}</li>
                        <li>{{n.message3}}</li>
                        <li>{{n.message4}}</li>
                        <div v-if="n.message5 == ''"><br/></div>
                            <div v-if="n.message5 != ''">
                              <li>{{n.message5}}</li>
                            </div>

                    </ul>
                     
                    </div>
                    <div s> </div>
                    <div class="text-center">
                      <output style="color:#ef8932;font-size:24px;font-weight: bold;font-family: revert;font-family: 'Poppins';">R$</output>
                      <output style="color:#085587;font-size:72px;font-weight: bold;font-family: revert;font-family: 'Poppins';">{{n.valor_i}}</output>
                      <output style="color:#085587;font-size:24px;font-weight: bold;font-family: revert; font-family: 'Poppins';">,{{n.valor_f}}</output>
                      <output style="color:#085587;font-size:16px;font-family: revert;font-family: 'Poppins';">/MÊS</output>
                    </div>

                    <div>
                       <v-dialog
                          transition="dialog-top-transition"
                          max-width="600"
                        >
                          <template v-slot:activator="{ on, attrs }" >
                          <div class="text-center" style=";margin-bottom:10px;font-family:'Poppins';">
                            <v-btn @click="seleciona_plano(n.valor, n.id, n.descricao, n.total_dep, n.total_agr, n.carencia_titular, 
                      n.carencia_dependente, n.carencia_dependente_maior, n.carencia_agregado, n.id_caren_maior_65, n.custo_agregado_maior_65, n.custo_agregado_menor_65, n.total_dep_45, n.total_dep_65, n.total_dep_70, n.custo_agregado_maior_80)" style="color: white;font-size: 22px;font-weight: 900"
                                v-on="on" class="ma-2" rounded 
                                color="#F07D00"> TENHO INTERESSE 
                            </v-btn>
                          </div>
                          </template>

                          <template v-slot:default="dialog"  >
                                    <v-card>
                                      <v-toolbar
                                      text
                                        color="primary"
                                        dark
                                      > <h3>{{ n.descricao }}</h3>
                          </v-toolbar>

                                      <v-card-text>
                                        <div class="text-h8 pa-12"> 
                                          Para facilitar tenha em mãos documentos pessoais:
                                          <ul>
                                            <li>CPF</li>
                                            <li>Documento de Identificação (foto)</li>
                                            <li>Comprovante de Residencia</li>
                                        </ul>


                                        </div>
                                        <div>
                                            Requisitos:<br/>
                                            * Titular com Idade Maior que <strong> 18 Anos </strong> e Menor que <strong> 65 Anos</strong>.
                                        </div> 
                                      </v-card-text>
                                      <v-card-actions class="justify-end">
                                        <v-btn
                                          text
                                          @click="dialog.value = false"
                                        >Cancelar</v-btn>


                                    <v-btn :loading="showload" 
                                  color="primary"
                                  @click="handleLogin"
                                  
                                >
                                  Iniciar 

                                  <v-icon right dark>mdi-arrow-right</v-icon>
                                </v-btn>


                                      </v-card-actions>
                                    </v-card>
                                  </template>
                                </v-dialog>
                    </div>
                    
                  </div>
                </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>    
      </v-col>
    </v-row>
    <br/>

<v-dialog
      v-model="dialog_error"
      persistent
      max-width="340"
    >

    <v-card>
        <v-card-title class="headline">
         Ops! Houve um problema
        </v-card-title>
        <v-card-text>Servidor com problemas de comunicação, por favor tente novamente mais tarde ou entre em contato. <br/> <br/> Telefone para contato <strong> 0800 009 1700 </strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog_error = false"
          >
            OK
          </v-btn>

        </v-card-actions>
    </v-card>
</v-dialog>             


</div>
<rodape/>

</div>
</template>



<script>
import User from '../models/user'
import Rodape from '../views/itensPage/Rodape';
import Produto from './../services/produtos';
//import HeadPrevida from './../components/VuebaseLayoutLogin';


// @ is an alias to /src
//@click="reserve"
export default {



created(){  
     

},


components: { Rodape },

  data: () => ({
      diaglog: false,
      showload: false,
      loading: false,
      selection: 1,
      dialog_error:false,
      user: new User('', ''),

    contrato:{
          nome: '',
          cpf: '',
          sexo: '',
          nacionalidade: '',
          estadocivil: '',
          dataCarencia: '',
          vencimento: '',
          dataCarencia: '',
          condicao: '',
          qtdParcelas: '',
          id_plano: '',
          valorContrato: '',
          parcela: '',
          tipo: '',
          nascimento: '',
          rg: '',
          uf_expedidor: '',
          expedidor: '',
          mae: '',
          religiao: '',
          profissao: '',
          pai: '',
          cep: '',
          logradouro: '',
          complemento: '',
          endereco: '',
          n_res: '',
          bairro: '',
          cidade: '',
          uf_end: '',
          pais: '',
        cep_entrega: '',
        repetir_endereco: true,
          complemento_entrega: '',
          endereco_entrega: '',
          n_res_entrega: '',
          bairro_entrega: '',
          cidade_entrega: '',
          uf_end_entrega: '',
          pais_entrega: '',
          telefone_fixo: '',
          celular: '',
          email: '',
          celular2: '',
          empresa: 'Previda',
          cupom: '',
          token_promotor: '',

        },
          plano_selecionado: {
          valor: 0, 
          id: 0,
          descricao: '', 
          qtd_dep : 0, 
          qtd_agr : 0, 
          carencia_titular : 0, 
          carencia_dependente : 0, 
          carencia_dependente_maior : 0, 
          carencia_agregado : 0, 
          carencia_agregado_maior : 0,
          custo_agregado_menor_65: 0,
          custo_agregado_maior_65: 0,
        },

        model: [],
    }),
   /*
    component() {
      return HeadPrevida
    ,
  */
     mounted () {
      this.listar()
      const urlParams = new URLSearchParams(window.location.search);
      this.contrato.token_promotor = urlParams.get("ID");
    },

    methods: {
    

      listar () {
          Produto.lista().then(response => {
              this.model = response.data
              //alert(response.data.mensagem)
          }).catch(e => {
             
          }).finally(() => {

          })
        },
        
        handleLogin () {      
          this.showload = true;

         this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.showload = false;

              this.$router.push('/cadastro')
            },
            error => {
              this.dialog_error = true;
            },
          )

      
      },

      seleciona_plano(valor, id, descricao, qtd_dep, qtd_agr, carencia_titular, 
      carencia_dependente, carencia_dependente_maior, carencia_agregado, 
      carencia_agregado_maior, custo_maior_65, custo_menor_65, total_dep_45, total_dep_65, total_dep_70, custo_maior_80){

        this.$store.commit('setNomePlano', descricao);
        this.$store.commit('setvalorPlano', valor);
        this.$store.commit('setdescricaoPlano', descricao);
        this.$store.commit('setIdPlano', id);
        this.$store.commit('setqtd_depPlano', qtd_dep);
        this.$store.commit('setqtd_agrPlano', qtd_agr);
        this.$store.commit('setcarencia_titularPlano', carencia_titular);
        this.$store.commit('setcarencia_dependentePlano', carencia_dependente);
        this.$store.commit('setcarencia_dependente_maiorPlano', carencia_dependente_maior);
        this.$store.commit('setcarencia_agregadoPlano', carencia_agregado);
        this.$store.commit('setcarencia_agregado_maiorPlano', carencia_agregado_maior);
        this.$store.commit('setcusto_agregado_menor65anos', custo_menor_65);
        this.$store.commit('setcusto_agregado_maior65anos', custo_maior_65);
        this.$store.commit('setcusto_agregado_maior80anos', custo_maior_80);      
        this.$store.commit('settotal_dependente_45', total_dep_45);
        this.$store.commit('settotal_dependente_65', total_dep_65);
        this.$store.commit('settotal_dependente_70', total_dep_70);


        this.$store.commit('setListaDependente', [])
        this.$store.commit('setContrato', this.contrato)
        this.$store.commit('setValorEtapa1', false)
        this.$store.commit('setValorEtapa2', false)
        this.$store.commit('setValorEtapa3', false)
        this.dialog = true



      }
    },
  }
</script>

<style>
  li {
  list-style-type: none;
  position:relative;
}


@media (
  max-width:485px){ 
  .conf_desktop{ display: none; } 
  
  
  
  }

  @media (
  min-width:485px){ 
  .conf_mobile{ display: none; } 
  
  
  }



li:before {
  content: '•';
  display: inline-block;
  position: absolute;
  left: -1em;
  color:#085587;
}

.box_elevation {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
  min-height: 200px;
  width: 50vw;
  margin: 100px auto;
  background: white;
  border-radius: 5px;
}

</style>
