<template>
<div>

   


  <cabecalho/>
    <v-container fluid style="width:90%; margin-top:10px;">
      <v-container bg fill-height grid-list-md text-xs-center style="max-width: 500px">
        <v-row align="center">
          <v-form ref="form" style="margin-top:20px"> 
            <v-container bg fill-height grid-list-md text-xs-center fluid style="max-width: 500px">
             <v-checkbox v-model="aceitoOne"  :rules="[v => !!v || 'Campo Obrigatório']">
                <template v-slot:label>
                  <div>
              
                   Aceito o compartilhamento dos meus dados com 
              o Previda conforme nossos Termos de uso e politica de Privacidade.
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://adoptprodstorage.blob.core.windows.net/adopt-prod/organization/e93ba504-6d64-42df-9e9e-9adbbee834c5/disclaimer/7ed19cfe-80ca-483d-9a3c-d0038fa37409/policy/01H158SSJQ87HE9AVS8PVPM78F.pdf"
                          @click.stop
                          v-on="on"
                        >
                          Saiba mais ...
                        </a>
                      </template>
                      Ler Termo
                    </v-tooltip>
                    
                  </div>
                </template>
              </v-checkbox>

             
              <v-checkbox v-model="aceitoTwo"  :rules="[v => !!v || 'Campo Obrigatório']" :label="`Declaro que as informações acima prestadas são verdadeiras, e assumo a inteira 
              responsabilidade pelas mesmas.`">
              </v-checkbox>


            </v-container>
    
            <v-col class="d-flex" style="height:80px" cols="12" sm="12">
              <v-select v-model="dia_vencimento" prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Campo Obrigatório']" :items="vencimentos" label="Dia de Vencimento do Carnê" filled></v-select>
            </v-col> 
          </v-form>  
       </v-row>
        <v-row align="center">
          <v-col class="d-flex" style="height:80px" cols="12" sm="12">
                  <v-text-field prepend-inner-icon="mdi-cart-minus"
                    value="" v-model="nome_plano"
                    label="Plano Selecionado"
                    filled
                    readonly
                  ></v-text-field>
          </v-col> 
        </v-row>

    <v-row align="center">
         <v-col class="d-flex" style="height:80px"  cols="12" sm="12">

              <v-text-field prepend-inner-icon="mdi-briefcase" style="font-size:20px"
                value="" v-model="valor_plano"
                label="Valor do Plano"
                filled
                readonly
                prefix="R$"
              ></v-text-field>
        </v-col> 
    </v-row>
    
    <v-row align="center">
         <v-col class="d-flex" style="height:80px" cols="12" sm="12">
              <v-text-field prepend-inner-icon="mdi-account-multiple-plus" style="font-size:20px"
                value="" v-model="valor_adicional"
                label="Adicional Agregado"
                filled
                readonly
                prefix="R$"
              ></v-text-field>

    </v-col> 
    </v-row>
    <v-row align="center">
      <v-col class="d-flex" style="height:80px" cols="12" sm="12">

                    <v-text-field style="font-size:20px" prepend-inner-icon="mdi-barcode"
                      value="" v-model="valor_mensalidade"
                      label="Mensalidade"
                      filled
                      color="red"
                      readonly
                      prefix="R$"
                    ></v-text-field>

          </v-col> 
    </v-row>
    <v-row align="center">
      <v-col class="d-flex" style="height:80px" cols="12" sm="12">

              <v-text-field style="font-size:20px; font-color:red;" prepend-inner-icon="mdi-credit-card" 
                value="" v-model="valor_adesao"
                label="Valor Adesão"
                filled         
                color="success"
                readonly
                prefix="R$">
              </v-text-field>
      </v-col> 
    </v-row>

    <v-row align="center">
      <v-col class="d-flex" style="height:60px;font-color:red;color:green" cols="12" sm="12">

              <v-text-field style="font-size:20px; font-color:red;" prepend-inner-icon="mdi-credit-card" 
                value="" v-model="this.contrato_selecionado.cupom"
                label="CUPOM"
                filled         
                color="success"
                readonly>
              </v-text-field>
      </v-col> 
    </v-row>

  </v-container>

<br/>

      <v-card-actions >
          <v-container bg fill-height grid-list-md text-xs-center style="max-width: 500px">
           

            <v-row  style="width: 300px; max-width: 500px" align="center" justify="center"  v-if="this.aceitoTwo == true && this.aceitoOne == true">



              <v-col cols="12" sm="12">
                    <v-btn block color="warning" x-large @click="dialog_ipt = true"> ENVIAR DOCUMENTOS
                      <v-icon right dark>mdi-cloud-upload</v-icon>
                    </v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                    <v-btn :loading="showload" x-large  block color="success" :disabled="setDisabled == true" v-if="dados_pagamento.venda_aprovado == false"
                    class="mr-4" @click="prosseguir">PAGAR ADESÃO
                          <v-icon right dark>mdi-credit-card</v-icon>
                    </v-btn>
                </v-col>

                <v-col cols="12" sm="12">
                    <v-btn :loading="showload" x-large  block color="lime lighten-3" :disabled="setDisabled == true" v-if="dados_pagamento.venda_aprovado == false"
                    class="mr-4" @click="quero_pagar_depois">AGENDAR PAGAMENTO
                          <v-icon right dark>mdi-calendar-clock</v-icon>
                    </v-btn>
                </v-col>

                
              <v-col cols="12" sm="12">
                   <v-btn :loading="showload" x-large block color="success" v-if="dados_pagamento.venda_aprovado"
                    class="mr-4"
                    @click="concluir">SAIR
                          <v-icon right dark>mdi-close</v-icon>
                  </v-btn>
              </v-col>
            </v-row>
          </v-container>


       
      </v-card-actions>
        
    <v-banner style="margin-top:20px;"
  >
    <template v-slot:actions>

<v-dialog 
      v-model="dialog_depois"
      persistent
      max-width="600px" 
    >
    
      <v-card>
         <v-toolbar color="success" dark> Pagar Depois</v-toolbar>
        <br/>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" > 

            <v-row>
             <h3> Nossa equipe entrará em contato para agendar a melhor forma de pagamento da adesão.  </h3>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
                  <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            text
            @click="clearCard"
          >
            Fechar
          </v-btn>
          <v-btn :loading="showload"
            color="success"
                        @click="pagar_depois">

           <v-icon right dark>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
        <br/>
      </v-card>
      
    </v-dialog>

    <v-dialog 
      v-model="dialog_sucesso_depois"
      persistent
      max-width="600px" 
    >
    
      <v-card>
         <v-toolbar color="success" dark> Mensagem</v-toolbar>
        <br/>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" > 

            <v-row>
             <h3> Confirmado com sucesso. Aguarde! Entraremos em contato. </h3>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
                  <v-spacer></v-spacer>
          <v-spacer></v-spacer>


          <v-btn :loading="showload"
            color="success"
                        @click="ok_finalizar">

           <v-icon right dark>mdi-check</v-icon>
            OK
          </v-btn>
        </v-card-actions>
        <br/>
      </v-card>
      
    </v-dialog>


<v-dialog 
      v-model="dialog"
      persistent
      max-width="600px" 
    >
    
      <v-card>
         <v-toolbar color="success" dark> Pagamento com Cartão de Crédito</v-toolbar>
        <br/>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" > 

            <v-row>
              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-text-field  v-model="dados_pagamento.cpf_pagador" v-mask="'###.###.###-##'"  Required  label="CPF do Pagador*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>

              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-text-field v-model="dados_pagamento.nome_cartao" label="Nome impresso no cartão*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="6">
                <v-text-field v-model="dados_pagamento.numero_cartao" label="Número*" v-mask="'#### #### #### ####'"  :rules="[v => !!v || 'Campo Obrigatório']"  v-on:change="ler_bandeira"    outlined dense></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field value="" v-model="dados_pagamento.bandeira" label="Bandeira" :rules="[v => !!v || 'Campo Obrigatório']"  outlined dense readonly></v-text-field>
              </v-col> 

             <v-col class="d-flex" style="height:60px" cols="6" sm="3">
                  <v-select v-model="dados_pagamento.mes_vencimento"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="meses" dense label="Mês*" outlined></v-select>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="3">
                <v-text-field v-model="dados_pagamento.ano_vencimento" label="Ano*"   minlength="4" v-mask="'####'" :rules="[rules_ano_cartao.required, rules_ano_cartao.counter]"  outlined dense></v-text-field>
              </v-col>

               <v-col class="d-flex" style="height:60px" cols="6" sm="3">
                <v-text-field v-model="dados_pagamento.cvv" label="CVV*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
                  <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            text
            @click="clearCard"
          >
            Fechar
          </v-btn>
          <v-btn :loading="showload"
            color="success"
                        @click="paga">

           <v-icon right dark>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
        <br/>
      </v-card>
      
    </v-dialog>
    

    <v-dialog
      v-model="dialog_ipt"
      persistent
      max-width="600px"
    >

      <v-card >
        <v-card-title>
          <span class="headline">Documentos</span>
        </v-card-title>
        <v-card-text >
 
                    <v-row no-gutters justify="center" align="center">
                    <v-col cols="12">
                      <v-file-input color="red"
                        show-size
                        label="Clique para Selecionar" 
                        @change="selectFile" 
                      ></v-file-input>
                    </v-col>

                    <v-col cols="6" class="pl-2" justify="center" align="center">
                      <v-btn color="success" block  @click="upload" :loading="showload_img">
                        Enviar Anexo
                        <v-icon right dark>mdi-cloud-upload</v-icon>
                      </v-btn>
                    </v-col>
                  

                  </v-row>
                  <h5>
                    <br/>
                   DOCUMENTOS PARA ENVIO: <br/>
                  •	Doc. válido de identificação do titular <br/>
                  •	Comprovante de endereço de até 3 meses de emitido <br/>
                  * Limite de 4 arquivos <br/> 

                  </h5>
<v-list dense >
      <v-subheader>Arquivos enviados com sucesso:</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in listafiles"
          :key="i"
        >

            <v-icon right >mdi-camera</v-icon>
            <v-list-item-title v-text="item.no"></v-list-item-title>

        </v-list-item>
      </v-list-item-group>
    </v-list>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="blue darken-1"
            text
            @click="dialog_ipt = false"
          >
            Sair
          </v-btn>

         
        </v-card-actions>
      </v-card>
    </v-dialog>


    </template>
  </v-banner>

<v-dialog
      v-model="dialog_infor"
      width="500"
    >

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Alerta
        </v-card-title>

        <v-card-text>
          Você não enviou documentos necessário para validação do seu contrato. A sua carência só iniciará após envio de todos os documentos obrigatórios para a conferência das informações preenchidas.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_infor = false"
          >
              OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<v-dialog 
        transition="dialog-bottom-transition"
        max-width="600"  v-model="dialog_cielo">

        <template>
          <v-card>
          <v-toolbar color="success" dark> <span class="headline">Informação</span></v-toolbar>

            <v-card-text v-if="dados_pagamento.venda_aprovado" >
              <div id="pnl">

                <br/>
                   <h3> <v-icon large color="green darken-2">mdi-check</v-icon> PAGAMENTO APROVADO</h3> <br/>
                    <h4> Seu cadastro seguirá para o setor de análise, breve entraremos em contato. Qualquer dúvida ligar para 0800 009 1700 </h4> <br/>
                    <v-row>
                   <h4>Você escolheu o {{plano_selecionado.descricao}}</h4>
                   </v-row>                    
                   <v-row>
                    <h4>Valor: R$  {{dados_pagamento.valor}}</h4>
                   </v-row>
                  <v-row>
                    <h4>Bandeira:  {{msg_bandeira}}</h4>
                  </v-row>
                  <v-row>
                    <h4>Data: {{ new Date() | dateFormat('DD/MM/YYYY') }} </h4>
                  </v-row>
                  <v-row>
                    <h4>Nº da Transação: {{dados_pagamento.id_contrato}}/{{dados_pagamento.cod_transacao}}</h4><br/>
                  </v-row> 
                    <v-row>
                    <h4>CUPOM: {{contrato_selecionado.cupom}}</h4><br/>
                  </v-row> 
                 
              </div>  
            </v-card-text>



              <v-card-actions  v-if="dados_pagamento.venda_aprovado">
                <v-container bg fill-height grid-list-md text-xs-center style="max-width: 500px">
                  <v-row  style="width: 300px; max-width: 500px" align="center" justify="center" >
                   
                    <v-col cols="12" sm="12">
                          <v-btn
                                  
                                  color="warning" block
                                  @click="print"
                                >Imprimir <v-icon right dark> mdi-printer
                                </v-icon>
                          </v-btn>
                      </v-col>
                    <v-col cols="12" sm="12">
                        <v-btn block color="success" @click="concluir"
                                >Finalizar e Sair<v-icon right dark> mdi-check
                        </v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
           </v-card-actions>





            <v-card-text v-if="dados_pagamento.venda_aprovado == false">
              <br/>
                  <h3> <v-icon large color="red darken-2">mdi-close</v-icon> Pagamento não foi concluido, verifique os dados do cartão e tente novamente.</h3> <br/>
                    <h4>Mensagem: {{dados_pagamento.mensagem_cielo}}</h4>
            </v-card-text>
           

               <v-card-actions class="justify-end" v-if="dados_pagamento.venda_aprovado == false">
              <v-btn
                text
                color="success" @click="dialog_cielo = false"
              >OK</v-btn>
            </v-card-actions>






          </v-card>
        </template>
      </v-dialog>

     


  
    </v-container>
<rodape/>

  </div> 

</template>

<script>

import Contrato from '../services/contrato'
import Pagamento from '../services/pagamento'
import Rodape from '../views/itensPage/RodapeInterno';

import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Cabecalho from '../views/itensPage/Cabecalho';
//import FormDataPost from '..services/upload';
import UploadService from "../services/uploadFilesService";

//...

  export default {

  name: "upload-files",

   computed:{
    ...mapGetters([
            'aceite1', 'aceite2'
        ]),
   },

  components: {Cabecalho, Rodape },
   
       data: () => ({ 

          globalOptions: {
            name: '_blank',
            specs: [
              'fullscreen=yes',
              'titlebar=yes',
              'scrollbars=yes'
            ],
            styles: [
              'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
              'https://unpkg.com/kidlat-css/css/kidlat.css'
            ]
          },
          localLandScapeOptions: {
            styles: [
              'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
              'https://unpkg.com/kidlat-css/css/kidlat.css',
              './landscape.css'
            ]
          }
        
      }),

  created() {


    this.$store.commit('setValorPosEtapa', 5)
    if(this.plano_selecionado.id == ''){
      this.$router.push('/')
    }

    if(this.etapa4 == false){
      this.$router.push('/verificacao')
    }
    
    Contrato.valores(this.contrato_selecionado.id).then(response => {
     this.valor_adicional = response.data.agregado
     this.valor_mensalidade = (response.data.mensalidade).toFixed(2);
     this.valor_adesao = (response.data.adesao).toFixed(2);
    });

    this.nome_plano = this.plano_selecionado.descricao
    this.valor_plano = this.plano_selecionado.valor
    //this.valor_adicional = this.valor_total_agregado
    //this.valor_mensalidade = (this.plano_selecionado.valor + this.valor_total_agregado).toFixed(2);
    //this.valor_adesao = (this.plano_selecionado.valor + this.valor_total_agregado).toFixed(2);
 },

  computed:{
    ...mapGetters([
            'contrato_selecionado' , 'plano_selecionado' , 'valor_total_agregado', 'listafiles', 'etapa4', 'listadependentes'
        ]),
  },


  
 
 data: () => ({ 

        msg_up: '',
        fileInfos: [],
        showload_img: false,
        dialog_infor: false,
        dialog_ipt: false,
        dialog: false,
        dialog_depois: false,
        date_ft: new Date(),
        dialog_cielo: false,
        dialog_sucesso_depois: false,
        contrato_aceito: false,
        showload: false,
        dia_vencimento: '',
        snackbar: false,
        text_snack: '',
        nome_plano: '',
        valor_plano: '',
        valor_adicional: '',
        valor_mensalidade: '',
        valor_adesao: '',
        msg_bandeira: '',
        aceitoOne: false,
        aceitoTwo: false,
        checkbox: true,
                  currentFile: undefined,
          progress: 0,
          dialog_aceito_one: true,

          message: "",
          fileInfos: [],
        
        dados_pagamento:{
              cpf_pagador: '',
              nome_cartao: '',
              numero_cartao: '',
              mes_vencimento: '',
              ano_vencimento: '',
              valor: 0,
              cod_transacao: 0,
              tipo: 'C',
              data: '', 
              cvv: '',
              contrato: 0,
	            mensagem_cielo: '',
              venda_aprovado: false,
              bandeira: '',
              
        },

    rules: [
        value => !!value || 'Required.',
       // value => (value && value.length >= 3) || 'Min 3 characters',
       //required: value => !!value || 'Required.',
       //counter: value => value.length < 5 || 'Max 20 characters',
      ],



    rules_ano_cartao: {
          required: value => !!value || 'Campo Obrigatório',
          counter: value => value.length > 3 || '4 digitos',
      },

    select: null,
    vencimentos: [
        '01',
        '05',
        '15',
        '20',
        '25'

      ], 

      meses: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'

      ], 

    }),

  methods:{

    aceite_termo_one(){
          this.$store.commit('setAceite1', true)
    },

    aceite_termo_two(){
          this.$store.commit('setAceite2', true)
    },

    voltar(){
           this.$router.push('dependente')
    },
/*
    onUpload() {
      console.log('------>>>>>'+this.file)

      const fd = new FormData();
      //fd.append('image', this.file, this.file.name)
      fd.append('pasta', this.file, this.file.name)
      axios.post('https://maestrix.grupoparque.com.br/apiprevida/api/foto/', fd).then(res => {console.log('teste')})
    },

*/
    print () {
          this.$htmlToPaper('pnl', null, () => {
          });
        },


    clearCard () {  

        this.$refs.form_card.reset();
        this.dialog = false;
        this.dialog_depois = false;
        /*
        this.cpf_pagador = '',
        this.nome_cartao = '',
        this.numero_cartao = '',
        this.mes_vencimento = '',
        this.ano_vencimento = '',
        this.valor = 0,
        this.cod_transacao = 0,
        this.tipo = 'C',
        this.data = '',
        this.cvv = '',
        this.id_contrato = 0,
	      this.mensagem_cielo = '',
        this.venda_aprovado = false,
        this.bandeira = ''
        */
      },
      
      clear () {       
        this.$refs.form.reset();
      },


      quero_pagar_depois(){
              this.dialog_depois = true
             if (this.listafiles.length<2) {
              this.dialog_infor = true
            }
      },

      
      async pagar_depois(){
        if(this.$refs.form.validate()){
            this.showload = true;
            this.$store.commit('setParcela', this.valor_total_agregado + this.plano_selecionado.valor)
            this.$store.commit('setDiaVencimento', this.dia_vencimento)
            this.contrato_selecionado.nome_plano = this.plano_selecionado.descricao
            this.contrato_selecionado.situacao = 'AGENDAR PAGAMENTO'
            Contrato.salvar(this.contrato_selecionado).then(response => {
             //this.dialog = true
             this.dialog_depois = false
             this.dialog_sucesso_depois = true; 

            }).catch(e =>{

            }).finally(() => {
              this.showload = false;
              this.$router.push('contratual')
          });
        }
      },

   
      ok_finalizar(){
              
              this.dialog_sucesso_depois = false; 
              this.$router.push('/')


      },

      selectFile(file) {
        this.progress = 0;
        this.currentFile = file;
      },

      async prosseguir(){
        if(this.$refs.form.validate()){
            this.showload = true;
            this.$store.commit('setParcela', this.valor_total_agregado + this.plano_selecionado.valor)
            this.$store.commit('setDiaVencimento', this.dia_vencimento)
            this.contrato_selecionado.nome_plano = this.plano_selecionado.descricao
            Contrato.salvar(this.contrato_selecionado).then(response => {
            
            if (this.listafiles.length<2) {
                      this.dialog_infor = true
            }else{
            }

                      this.dialog = true

            }).catch(e =>{

            }).finally(() => {
              this.showload = false;
              this.$router.push('contratual')
          });
        }
      },

      ler(){
          this.$store.commit('setContrato', this.contrato_selecionado);
          let routeData = this.$router.resolve({name: 'Modelo', query:  ''});
          window.open(routeData.href, '_blank');
          //this.$router.push('modelo')
      },
      selectFile(file) {
        this.progress = 0;
        this.currentFile = file;
      },

     

      async paga(){
        this.dados_pagamento.contrato = this.contrato_selecionado.id
        //this.dados_pagamento.valor = this.valor_total_agregado + this.plano_selecionado.valor
        if(this.$refs.form_card.validate() && this.$refs.form_card.validate()){
            this.showload = true;

            Pagamento.salvar(this.dados_pagamento).then(response => {
                    
                    this.dados_pagamento.mensagem_cielo = response.data.mensagem_cielo;
                    this.dados_pagamento.venda_aprovado = response.data.venda_aprovado;
                    this.dados_pagamento.cod_transacao = response.data.cod_transacao;
                    this.dados_pagamento.valor = response.data.valor
                    this.dados_pagamento.bandeira = response.data.bandeira
                    this.msg_bandeira = response.data.bandeira

            }).catch(e =>{
                    this.clearCard();
                    this.dados_pagamento.mensagem_cielo = 'Falha na Transação, verifique os dados do seu cartão';
                    this.dados_pagamento.venda_aprovado = false;

            }).finally(() => {
              this.showload = false;
              this.clearCard();
              this.dialog = false;
              this.dialog_cielo = true;   
              this.$store.commit('setIdPlano', '')

           

          });
        }
      },


    


    async ler_bandeira(){
    
      this.showload = true;
      Pagamento.ler_bandeira(this.dados_pagamento).then(response => {
                 this.dados_pagamento.bandeira = response.data.bandeira;
              }).catch(e =>{
                            //console.log(e)

             }).finally(() => {
                this.showload = false;
            });
        
      },

      setDisabled: function(){
            if(this.aceito1 == false || this.aceito2 == false){
              return true
            }else { return false}
          },

      concluir(){
            this.$store.commit('setIdPlano', '')
            this.$router.push('/')

      },
      upload() {


        if (!this.currentFile) {
          return;
        }

        if (this.listafiles.length>3) {
          return;
        }

        

        this.showload_img = true;
        this.message = "";

        UploadService.upload(this.contrato_selecionado.id, this.currentFile, (event) => {
          //this.progress = Math.round((100 * event.loaded) / event.total);
                                  

        })
          .then((file) => {

               var item = { no: this.currentFile.name}

                this.listafiles.push(item);
                this.$store.commit('setFiles', item)

            this.showload_img = false;
            // this.fileInfos.concat = 'teste'//this.currentFile.name;

            this.progress = 0;
            this.currentFile.name = undefined;


          })
          .catch(() => {
            this.progress = 0;
            this.showload_img = false;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
      },

       


  }
  }

</script>
