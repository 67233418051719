<template>
  <v-app>
  <v-main style="background-color:#FFFFFF;">
         <router-view></router-view>
  </v-main>
</v-app>
</template>

<script>
export default {
    computed:{
      isAuthenticated() {
        return this.$store.state.isTopBar
      },
    },
  };
</script>
